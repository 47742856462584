.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.loader {
  --loader-width: 0.2rem;
  --loader-speed: 1.8s;
  --loader-background: #dddee8;
  --loader-color: #2f3071;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: var(--loader-width) solid var(--loader-color);
  border-right: var(--loader-width) solid var(--loader-background);
  border-bottom: var(--loader-width) solid var(--loader-background);
  border-left: var(--loader-width) solid var(--loader-background);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 var(--loader-speed) infinite ease-in-out;
  -moz-animation: load8 var(--loader-speed) infinite ease-in-out;
  animation: load8 var(--loader-speed) infinite ease-in-out;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: translateZ(0) rotate(0deg);
    transform: translateZ(0) rotate(0deg);
  }
  100% {
    -webkit-transform: translateZ(0) rotate(360deg);
    transform: translateZ(0) rotate(360deg);
  }
}
@-moz-keyframes load8 {
  0% {
    -moz-transform: translateZ(0) rotate(0deg);
    transform: translateZ(0) rotate(0deg);
  }
  100% {
    -moz-transform: translateZ(0) rotate(360deg);
    transform: translateZ(0) rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: translateZ(0) rotate(0deg);
    -moz-transform: translateZ(0) rotate(0deg);
    transform: translateZ(0) rotate(0deg);
  }
  100% {
    -webkit-transform: translateZ(0) rotate(360deg);
    -moz-transform: translateZ(0) rotate(360deg);
    transform: translateZ(0) rotate(360deg);
  }
}
